import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import printBg from "../../assets/image/print_bgg.png";
import "./PrintPage.css";

const PrintPage = ({ id, name1, name2, name3, name4, name5, name6 }) => {
  return id === "685" ? (
    <Container
      fluid
      className={`print page print_page_text ${
        id === "685" ? "portrait" : "landscape"
      }`}
    >
      <Row>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name1?.[0]}</span>
            <span className="other_latters">{name1?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name2?.[0]}</span>
            <span className="other_latters">{name2?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name3?.[0]}</span>
            <span className="other_latters">{name3?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name4?.[0]}</span>
            <span className="other_latters">{name4?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name5?.[0]}</span>
            <span className="other_latters">{name5?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name6?.[0]}</span>
            <span className="other_latters">{name6?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
      </Row>
    </Container>
  ) : (
    <Container
      fluid
      className={`print page print_page_text ${
        id === "685" ? "portrait" : "landscape"
      }`}
    >
      <Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name1?.[0]}</span>
            <span className="other_latters">{name1?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name2?.[0]}</span>
            <span className="other_latters">{name2?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name3?.[0]}</span>
            <span className="other_latters">{name3?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
      </Col>
      <Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name4?.[0]}</span>
            <span className="other_latters">{name4?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name5?.[0]}</span>
            <span className="other_latters">{name5?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
        <Col
          xs={12}
          className="sticker"
          width={id === "685" ? "16.8cm" : "11cm"}
          height={id === "685" ? "6.2cm" : "3.7cm"}
        >
          <h1
            className="name_text"
            style={{
              fontSize: id === "685" ? "73.77px" : "63.84px",
              marginTop: "20px",
            }}
          >
            <span className="first_letter">{name6?.[0]}</span>
            <span className="other_latters">{name6?.slice(1)}</span>
          </h1>
          <img
            src={printBg}
            width="100%"
            height="auto"
            className="nutella-print"
          />
        </Col>
      </Col>
    </Container>
  );
};

export default PrintPage;
