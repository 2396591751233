import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import bigArrow from "../../assets/big-arrow.png";
import bigShadow from "../../assets/image/Shadow.svg";
import logo from "../../assets/logo.png";
import logoAndText from "../../assets/image/title.png";
import smallArrow from "../../assets/small-arrow.png";
import smallShadow from "../../assets/small-shadow.png";
import "./HomePage.css";
import smallShadowSvg from "../../assets/image/Shadow.svg";
import bigNutella from "../../assets/image/bigoo.png";
import bigNutellaMobile from "../../assets/image/mobile_big.png";
import smallNutella from "../../assets/image/smalloo.png";
import smallNutellaMobile from "../../assets/image/mobile_small.png";

const HomePage = () => {
  return (
    <div className="app">
      <Container
        className={"home-page"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        fluid
      >
        <div className="my_nav">
          <div className="main_nav_mobile_text">
            <img src={logoAndText} width="100%" alt="" />
          </div>
          <div className="main_nav_text">
            <div
              style={{
                display: "flex",
              }}
            >
              <div className="logo_block">
                <img src={logo} alt="" />
              </div>
              <div
                style={{
                  paddingTop: "16px",
                }}
              >
                <span>has</span>
                <span>a</span>
                <span>new</span>
                <span>name.</span>
              </div>
            </div>
            <div
              style={{
                paddingTop: "16px",
              }}
              className="yours_text_block"
            >
              <div
                style={{
                  display: "inline-block",
                }}
              >
                <span
                  style={{
                    margin: 0,
                  }}
                >
                  Y
                  <span
                    style={{
                      color: "#ED1D24",
                      margin: 0,
                    }}
                  >
                    ours
                  </span>
                  .
                </span>
              </div>
              <div className="line_bg"></div>
            </div>
          </div>
        </div>
        <Row
          className="bubble_bg"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></Row>
        <Row className="row_item">
          <Col lg={6} md={12} sm={12} xs={12} className="big_block_col">
            <div className="big_block">
              <Link
                to="/selected/685"
                style={{
                  paddingRight: "20px",
                  zIndex: 999999,
                  width: "570px",
                }}
              >
                <img
                  src={bigNutella}
                  width="100%"
                  alt=""
                  className="bigNutella"
                />
                <img
                  src={bigNutellaMobile}
                  width="70%"
                  alt=""
                  className="bigNutellaMobile"
                />
              </Link>
            </div>
            <div className="big_block_shadow">
              <img src={smallShadowSvg} width="100%" alt="" />
            </div>
            <h2 className="text_gr_big">680 GR</h2>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} className="small_block_col">
            <div className="small_block">
              <Link
                to="/selected/350"
                style={{
                  paddingLeft: "20px",
                  zIndex: 999999,
                  width: "500px",
                }}
              >
                <img
                  src={smallNutella}
                  width="100%"
                  alt=""
                  className="smallNutella"
                />
                <img
                  src={smallNutellaMobile}
                  width="70%"
                  alt=""
                  className="smallNutellaMobile"
                />
              </Link>
            </div>
            <div className="small_block_shadow">
              <img src={smallShadowSvg} width="90%" alt="" />
            </div>
            <h2 className="text_gr_small">350 GR</h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePage;
