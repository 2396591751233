import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const InputItems = ({
  name1,
  name2,
  name3,
  name4,
  name5,
  name6,
  setName1,
  setName2,
  setName3,
  setName4,
  setName5,
  setName6,
}) => {
  return (
    <>
      <Container className={'col-6'} style={{
        marginTop:'20px'
      }}>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div>
              <input
                className="nutella_input"
                type="text"
                value={name1}
                placeholder="NAME 1"
                onChange={(e) => {
                  e.target.value.length < 9 && setName1(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div >
              <input
                className="nutella_input"
                type="text"
                value={name2}
                placeholder="NAME 2"
                onChange={(e) => {
                  e.target.value.length < 9 && setName2(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div>
              <input
                className="nutella_input"
                type="text"
                value={name3}
                placeholder="NAME 3"
                onChange={(e) => {
                  e.target.value.length < 9 && setName3(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div style={{}}>
              <input
                className="nutella_input"
                type="text"
                value={name4}
                placeholder="NAME 4"
                onChange={(e) => {
                  e.target.value.length < 9 && setName4(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div style={{}}>
              <input
                className="nutella_input"
                type="text"
                value={name5}
                placeholder="NAME 5"
                onChange={(e) => {
                  e.target.value.length < 9 && setName5(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div style={{}}>
              <input
                className="nutella_input"
                type="text"
                value={name6}
                placeholder="NAME 6"
                onChange={(e) => {
                  e.target.value.length < 9 && setName6(e.target.value.toLocaleLowerCase());
                }}
              />
            </div>
          </Col>

          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
            className="nutella_input_submit"
              onClick={() => {
                window.print();
              }}
            >
              SUBMIT
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InputItems;
