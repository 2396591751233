import React, { useState } from "react";
import { useParams } from "react-router-dom";
import selectedBig from "../../assets/image/big_item.png";
import selectedSmall from "../../assets/image/small_item.png";
import InputItems from "../../components/inputItems/InputItems";
import PrintPage from "../PrintPage/PrintPage";
import "./SelectedPage.css";
import { Row } from 'react-bootstrap';

const SelectedPage = () => {
  const [name1, setName1] = useState(null);
  const [name2, setName2] = useState(null);
  const [name3, setName3] = useState(null);
  const [name4, setName4] = useState(null);
  const [name5, setName5] = useState(null);
  const [name6, setName6] = useState(null);
  const { id } = useParams();

  return (
    <>
      <div className="form-name" style={{overflow:"hidden"}}>
        <Row className="selected_container">
          <div className="selected_img">
            <img
              src={id === "685" ? selectedBig : selectedSmall}
              width="100%"
              alt=""
            />
          </div>
        </Row>
        <InputItems
          name1={name1}
          name2={name2}
          name3={name3}
          name4={name4}
          name5={name5}
          name6={name6}
          setName1={setName1}
          setName2={setName2}
          setName3={setName3}
          setName4={setName4}
          setName5={setName5}
          setName6={setName6}
        />
      </div>
      <PrintPage
        id={id}
        name1={name1}
        name2={name2}
        name3={name3}
        name4={name4}
        name5={name5}
        name6={name6}
      />
    </>
  );
};

export default SelectedPage;
